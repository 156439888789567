<template>
	<div class="main">
		<div class="header">
			<van-icon name="arrow-left" class="icon" @click="() => {this.router.back()}"/>
			<span>家长信息填报</span>
      <div></div>
<!--			<van-icon name="plus" class="icon" @click="AddTo" />-->
		</div>
		<div class="body">
			<h2>
				您的孩子名字是：<span class="username">{{ username }}</span>
			</h2>
			<p class="str">请确认您和家人的信息，方便学校与家长沟通</p>
			<div class="body_data" v-if="family.length === 0">
				<img class="custom-image" src="../assets/NoData.png" alt=""/>
				<p>暂无数据</p>
			</div>
      <div v-else style="margin-top: 50px">
        <div class="body_item" v-for="(item,index) in family" :key="index" @click="details(item)">
          <div style="display: flex;justify-content: space-between;align-items: center">
            <div style=";display: flex;width: 70%;align-items: center">
              <div style="font-size: 19px;font-weight: 400;color: #333333;max-width: 65%; overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{{item.name}}</div>
              <div style="font-size: 13px;font-weight: 400;color: #999999;display: flex;align-items: flex-end;margin-left: 4px;">({{ item.relation_name }})</div>
            </div>
            <div style="font-size: 13px;font-weight: 400;color: #333333;">{{item.phone}}</div>
          </div>
          <div style="display: flex;margin-top: 24px" v-if="item.wish">
            <img :src="imgArr[1]" alt="" style="width: 21px;height: 17px;margin-right: 4px"/>
            <div style="font-size: 13px;font-weight: 400;color: #333333;">您对孩子的期望</div>
          </div>
          <div v-else style="text-align: center;margin-top: 24px;font-size: 13px;font-weight: 400;line-height: 23px;color: #999999;word-break: break-all;border: 1px dashed #333333;height: 24px;border-radius: 4px">+点此填写您对孩子的期望</div>
          <div style="margin-top: 13px;font-size: 13px;font-weight: 400;line-height: 23px;color: #999999;word-break: break-all;">
            {{item.wish}}
          </div>
          <div style="display: flex;margin-top: 24px" v-if="item.character_description">
            <img :src="imgArr[2]" alt="" style="width: 21px;height: 17px;margin-right: 4px"/>
            <div style="font-size: 13px;font-weight: 400;color: #333333;">孩子的性格</div>
          </div>
          <div v-else style="text-align: center;margin-top: 24px;font-size: 13px;font-weight: 400;line-height: 23px;color: #999999;word-break: break-all; border: 1px dashed #333333;height: 24px;border-radius: 4px">+点此填写您眼中孩子的性格</div>
          <div style="margin-top: 13px;font-size: 13px;font-weight: 400;line-height: 23px;color: #999999;word-break: break-all; ">
            {{ item.character_description }}
          </div>
        </div>
      </div>
		</div>
    <img src="../assets/add.png" alt="" style="position: fixed;right: 24px;bottom: 48px;width: 65px;height: 65px" v-if="family.length!==3" @click="()=>{ this.$router.push('add') }">
	</div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import axios from '../utils/util'
import img2 from '../assets/1.png'
import img1 from '../assets/2.png'
import img3 from '../assets/3.png'
export default {
	setup() {
		const router = useRouter()
		let username = ref('')
		let family = ref([])
		let imgArr = ref([img1, img2, img3])
		return {
			router,
			username,
			family,
			imgArr
		}
	},
	methods: {
		AddTo() {
			this.router.push('/add')
		},
		details(value) {
			this.$router.push('/details?id=' + value.id)
		}
	},
	created() {
		axios({
			url: 'fill/front',
			method: 'post',
			data: {
				identifying: sessionStorage.getItem('user')
			}
		}).then(res => {
			this.username = res.data.data.student_name
			this.family = res.data.data.family
		})
	}
}
</script>

<style lang="less" scoped>
.main {
	width: 100%;
	min-height: 100vh;
  background: url('../assets/listbg.png') 0 10px fixed;
  //background-size: 110%;||cover|contain
  background-size: 100% 105%;
	//padding-bottom: 100px;
	.header {
		background-color: #eeeeee;
		height: 50px;
		line-height: 50px;
		text-align: center;
		color: #333;
		border: 1px solid #eee;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		font-size: 16px;
		padding: 0 10px;
		.icon {
			line-height: 50px;
			font-size: 24px;
		}
	}
	.body {
    padding: 0 10px 100px;
    margin-top: 40px;
		.username {
			color: #1d2088;
		}
		.str {

			font-weight: 400;
			color: #666666;
		}
		.body_data {
			text-align: center;

			font-weight: 400;
			color: #666666;
			margin-top: 100px;

			.custom-image {
				margin: auto;
			}
		}
	}
	.family {
		width: 80%;
		min-height: 110px;
		border: 1px solid #d7d7d7;
		margin-left: calc(10% - 20px);
		margin-top: 20px;
		padding: 20px;
		margin-bottom: 20px;
		background: #ffffff;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
		border-radius: 10px;
	}
}
.body_item{
  width: cale(100% - 40px);
  min-height: 150px;
  background: #FFFFFF;
  box-shadow: 0 3px 6px rgba(238, 238, 238, 0.16);
  border-radius: 9px;
  padding: 16px 20px;
  margin-bottom: 23px;
}
</style>